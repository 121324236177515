import React, { useEffect, useState } from "react"
import { Row, Col, Button, Card, Container, Form } from "react-bootstrap"
import ClassroomLayout from "../components/classroom/layout/classroom-layout"
import AuthService from "../services/AuthService"
import { toast } from "react-toastify"

export default function Account() {
  const [attributes, setAttributes] = useState({})
  const [password, setPassword] = useState({})
  const [saving, setSaving] = useState(false)

  useEffect(async () => {
    load()
  }, [])

  const load = async () => {
    const user = await AuthService.getCurrentUser()
    if (user) {
      setAttributes(user.attributes)
    }
  }

  const change = e => {
    setAttributes({ ...attributes, [e.target.name]: e.target.value })
  }

  const changePassword = e => {
    setPassword({ ...password, [e.target.name]: e.target.value })
  }

  const save = async () => {
    setSaving(true)

    if(await AuthService.updateAccount(attributes)){
      toast("Your account has been successfully updated.", {
        type: "success",
      })
    }

    load()
    setSaving(false)
  }
  const updatePassword = async () => {
    setSaving(true)
    if (password.new == password.new_confirm) {
      var success = await AuthService.changePassword(
        password.current,
        password.new
      )
      if (success) {
        toast("Your password has been successfully changed.", {
          type: "success",
        })
      }
    } else {
      toast("Please ensure entered passwords are the same.", {
        type: "warning",
      })
    }
    load()
    setSaving(false)
  }

  const [tab, setTab] = useState(0)

  const tabClick = (e, key) => {
    e.preventDefault()
    setTab(key)
  }

  return (
    <ClassroomLayout>
      <Container className="py-5">
        <h1>My Account</h1>
        <Row>
          <Col md="auto mb-4">
            <ul className="card card-tabs">
              <li>
                <a
                  className={0 === tab ? "active" : ""}
                  href="#"
                  onClick={e => tabClick(e, 0)}
                >
                  Account Details
                </a>
              </li>
              <li>
                <a
                  className={1 === tab ? "active" : ""}
                  href="#"
                  onClick={e => tabClick(e, 1)}
                >
                  Change Password
                </a>
              </li>
            </ul>
          </Col>
          <Col>
            {tab === 0 ? (
              <Card>
                <Card.Body>
                  <Form>
                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={attributes?.email}
                        onChange={change}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        name="given_name"
                        value={attributes.given_name}
                        onChange={change}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        name="family_name"
                        value={attributes.family_name}
                        onChange={change}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        name="phone_number"
                        value={attributes.phone_number}
                        onChange={change}
                      />
                    </Form.Group>
                  </Form>

                  <Button disabled={saving} onClick={save}>
                    {saving ? "Saving..." : "Save"}
                  </Button>
                </Card.Body>
              </Card>
            ) : (
              ""
            )}
            {tab === 1 ? (
              <Card>
                <Card.Body>
                  <Form>
                    <Form.Group className="mb-3">
                      <Form.Label>Current Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="current"
                        value={password?.current}
                        onChange={changePassword}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="new"
                        value={password.new}
                        onChange={changePassword}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="new_confirm"
                        value={password.new_confirm}
                        onChange={changePassword}
                      />
                    </Form.Group>
                  </Form>

                  <Button disabled={saving} onClick={updatePassword}>
                    {saving ? "Saving..." : "Save"}
                  </Button>
                </Card.Body>
              </Card>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Container>
    </ClassroomLayout>
  )
}
